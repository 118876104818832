// 样式转换器
const formatDate = (row, column, cellValue) => {
    if (!cellValue) return "";
    const date = new Date(cellValue);
    return new Intl.DateTimeFormat("zh-CN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    }).format(date);
};
const getDaysUntilExpire = (expireDate) => {
    const now = new Date();
    const date = new Date(expireDate);
    const timeDiff = date - now;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff > 0 ? `生效中·${daysDiff}天后过期` : "已过期";
};
const getTagType = (expireDate) => {
    const now = new Date();
    const date = new Date(expireDate);
    return date > now ? "success" : "danger";
};
const fromBoolean = (value) => {
    return value ? "已启用" : "已关闭";
};
const frompermission = (value) => {
    return value === 20 ? "权限开通" : "权限关闭";
};
const frompermissionTheme = (value) => {
    return value === 20 ? "success" : "danger";
};
const getBooleanTagTepe = (value) => {
    return value ? "success" : "danger";
};
const fromCourseState = (value) => {
    return value === 20 ? "已发布" : "已下架";
};
const fromCourseAuditing = (value) => {
    switch (value) {
        case 10:
            return "审核中"
        case 20:
            return "审核通过"
        case 30:
            return "审核驳回"
        default:
            return "未知状态";
    }
};
const fromCourseAssoModuleType = (value) => {
    switch (value) {
        case 10:
            return "目录视频";
        case 20:
            return "目录资料";
        case 30:
            return "课程首页图";
        case 40:
            return "课程宣传视频";
        case 50:
            return "课程详情图片";
        default:
            return "未知状态";
    }
};
const fromCourseVideoState = (value) => {
    switch (value) {
        case 5:
            return "待审核";
        case 10:
            return "审核中";
        case 20:
            return "审核通过";
        case 30:
            return "审核驳回";       
        default:
            return "未知状态";
    }
};
const getStrTagTepe = (value) => {
    return value ? value : '暂未配置';
};

const formatBytes = (bytes) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};
const formatCurrency = (amount) => {
    if (amount >= 10000) {
        return (amount / 10000).toFixed(2) + ' 万元';
    } else {
        return amount.toFixed(2) + ' 元';
    }
};
const formatDuration = (duration) => {
    if (duration === 0 || duration === '0' || duration === '00:00:00') return '-';
    if (!duration) return '';
    // 分割天数和时间部分
    const parts = duration.split('.');
    if (parts.length <= 2) {
        return duration; // 如果没有天数部分，直接返回
    }
    const days = parseInt(parts[0], 10);
    const timePart = parts[1];

    // 分割小时、分钟、秒和毫秒部分
    const [hours, minutes, seconds] = timePart.split(':').map((part, index) => {
        if (index === 3) {
            return part.padEnd(6, '0');
        }
        return part.padStart(2, '0');
    });
    // 计算总小时数
    const totalHours = days * 24 + parseInt(hours, 10);
    return `${totalHours}:${minutes}:${seconds}.${parts[2]}`;
};
export default {
    formatDate, getDaysUntilExpire, getTagType, fromBoolean, getBooleanTagTepe, frompermissionTheme,
    getStrTagTepe, formatBytes, formatCurrency, formatDuration, frompermission, fromCourseState, fromCourseAuditing,fromCourseAssoModuleType,fromCourseVideoState
}

