import { handleRequest, instance } from './commonutil';
//查询系统用户
const getUsers = async (search_name, pager) => {
    const params = {
        userNickName: search_name,
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize
    };
    const queryString = new URLSearchParams(params).toString();

    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.get(`/api/User/GetSysUsers?${queryString}`);
    return await handleRequest(httpRequest, '加载用户中...');
};
//查询系统角色
const getSysRoles = async (search_name, pager) => {
    const params = {
        roleName: search_name,
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.get(`/api/User/GetSysRoles?${queryString}`);
    return await handleRequest(httpRequest, '加载系统角色中...');
};
//查询系统路由
const getSysRoutes = async (search_name, pager) => {
    const params = {
        displaySummary: search_name,
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.get(`/api/User/GetSysRoutes?${queryString}`);
    return await handleRequest(httpRequest, '加载系统路由中...');
};
//查询用户角色
const getUserRoles = async (userId, roleName, pager, lack) => {
    const params = {
        userId: userId,
        roleName: roleName,
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize,
        lack: lack
    };
    const queryString = new URLSearchParams(params).toString();

    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.get(`/api/User/GetUserRoles?${queryString}`);
    return await handleRequest(httpRequest, '加载用户角色中...');
};
//用户添加角色
const userSettingRole = async (userId, routeIds) => {
    // 确保 roleids 是一个数组
    if (!Array.isArray(routeIds)) {
        routeIds = [routeIds];
    }
    const params = {
        userId: userId,
        roleIds: routeIds
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest  /api/User/UserSettingRole
    const httpRequest = () => instance.post(`/api/User/UserSettingRole?${queryString}`);
    return await handleRequest(httpRequest, '用户添加角色中...');
};
//用户移除角色
const userDeleteRole = async (userid, roleIds) => {
    // 确保 roleids 是一个数组
    if (!Array.isArray(roleIds)) {
        roleIds = [roleIds];
    }
    const params = {
        userId: userid,
        roleIds: roleIds
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.delete(`/api/User/UserDeleteRole?${queryString}`);
    return await handleRequest(httpRequest, '用户删除角色中...');
};
//查询角色路由
const getRoleRoutes = async (roleid, displaySummary, pager, lack) => {
    const params = {
        roleId: roleid,
        displaySummary: displaySummary,
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize,
        lack: lack
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.get(`/api/User/GetRoleRoutes?${queryString}`);
    return await handleRequest(httpRequest, '查询角色路由中...');
};
//角色配置路由
const roleSettingRoute = async (roleId, routeIds) => {
    // 确保 roleids 是一个数组
    if (!Array.isArray(routeIds)) {
        routeIds = [routeIds];
    }
    const params = {
        roleId: roleId,
        routeIds: routeIds
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/User/RoleSettingRoute?${queryString}`);
    return await handleRequest(httpRequest, '添加角色路由中...');
};
//角色移除路由
const roleDeleteRoute = async (roleId, routeIds) => {
    // 确保 roleids 是一个数组
    if (!Array.isArray(routeIds)) {
        routeIds = [routeIds];
    }
    const params = {
        roleId: roleId,
        routeIds: routeIds
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.delete(`/api/User/RoleDeleteRoute?${queryString}`);
    return await handleRequest(httpRequest, '角色移除路由中...');
};
//创建系统角色
const postCreateSysRole = async (roleForm) => {
    const params = {
        roleName: roleForm.roleName,
        description: roleForm.description
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(`/api/User/CreateSysRole?${queryString}`);
    return await handleRequest(httpRequest, '创建系统角色中...');
};
//删除系统角色
const DeleteRole = async (roleId) => {
    const params = {
        roleId: roleId
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.delete(`/api/User/DeleteRole?${queryString}`);
    return await handleRequest(httpRequest, '删除系统角色中...');
};
//修改角色信息
const putUpdateRole = async (updateRoleForm) => {
    const params = {
        roleId: updateRoleForm.id,
        roleName:updateRoleForm.roleName,
        description:updateRoleForm.description
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.put(`/api/User/UpdateSysRole?${queryString}`);
    return await handleRequest(httpRequest, '修改系统角色中...');
}
export default {
    getUsers, getUserRoles, getSysRoles, userSettingRole, userDeleteRole,
    getSysRoutes, getRoleRoutes, roleSettingRoute, roleDeleteRoute, postCreateSysRole, DeleteRole,putUpdateRole
};