<template>
    <div class="body">
        <div class="main">
            <sign_inPage></sign_inPage>
            <sign_upPage></sign_upPage>
            <switchPage></switchPage>
        </div>
    </div>
</template>

<script>
import switchPage from './components/switch.vue';
import sign_inPage from './components/sign_in.vue';
import sign_upPage from './components/sign_up.vue';
export default {
    name: 'LoginPage',
    components: {
        switchPage, sign_inPage, sign_upPage
    }
}
</script>

<style scoped>
@import './components/login.css';

:deep(.el-form-item.is-error .el-input__wrapper,
    .el-form-item.is-error .el-select__wrapper,
    .el-form-item.is-error .el-textarea__inner) {
    box-shadow: none !important;
}
</style>