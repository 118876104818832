<template>
    <div class="footer">
        <p style="margin-right: 10px;">微信同电话:13028792031</p>
        <el-link type="primary" href="https://beian.miit.gov.cn">陇ICP备2024011221号</el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link type="primary" href="https://beian.mps.gov.cn">甘公网安备62102202000159号</el-link>
        <el-divider direction="vertical"></el-divider>
        <p>Copyright © {{ lastYear }}-{{ currentYear }}</p>
    </div>
</template>
<script>
export default {
    name: "FooterPage",
    data() {
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;
        return {
            currentYear,
            lastYear
        };
    }
}
</script>

<style scope>
.footer {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 30px;
}
</style>