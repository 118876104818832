<template>
    <div class="switch" id="switch-cnt">
        <div class="switch__circle"></div>
        <div class="switch__circle switch__circle--t"></div>
        <div class="switch__container" id="switch-c1">
            <h2 class="switch__title title">欢迎回来 !</h2>
            <p class="switch__description description">将要使用您的账户登入系统</p>
            <button class="switch__button button switch-btn" @click="change">前往登录</button>
        </div>
        <div class="switch__container is-hidden" id="switch-c2">
            <h2 class="switch__title title">你好,朋友 !</h2>
            <p class="switch__description description">将要使用您的电话号码注册账户</p>
            <button class="switch__button button switch-btn" @click="change">跳转注册</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'switchPage',
    data() {
        return {
            sidebarOpen: true
        };
    },
    methods: {
        change() {
            const switchC1 = document.querySelector('#switch-c1');
            const switchC2 = document.querySelector('#switch-c2');
            const switchCircle = document.querySelectorAll('.switch__circle');
            const switchCtn = document.querySelector('#switch-cnt');
            switchCtn.classList.add('is-gx');
            setTimeout(() => {
                if (switchCtn) {
                    switchCtn.classList.remove('is-gx');
                }
            }, 1500);
            switchCtn.classList.toggle('is-txr');
            switchCircle.forEach(circle => circle.classList.toggle("is-txr"));
            //隐藏哪一个页面标题
            switchC1.classList.toggle('is-hidden');
            switchC2.classList.toggle('is-hidden');
            //通知对应的页面内容刷新
            this.sidebarOpen = !this.sidebarOpen;
            this.$emitter.emit('change', this.sidebarOpen);
        }
    },
    mounted() {
        this.change();
    }
};   
</script>


<style scoped>
@import './login.css';
/* 
    将源码中的css样式单独存放,在各组件中导入就可以。
    后续整理代码时建议，将全局样式和局部样式分开。 
*/
</style>