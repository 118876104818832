<template>
    <header>      
        <h1 style="color: #425049; font-size: 20px; margin-right: auto; margin-left: 20px;">灵燕空间</h1>
        <div class="centerTitleMenu">
            <ul>
                <li @click="navigateTo('默认首页')">首页总览</li>
                <li @click="navigateTo('Cert')">证书服务</li>
                <li @click="navigateTo('Course')">课堂服务</li>
                <li @click="navigateTo('System')">系统管理</li>
                <li @click="navigateTo('个人中心')">个人中心</li>
            </ul>
        </div>
        <el-dropdown :hide-on-click="false" style="margin-right: 10px;">
            <span class="el-dropdown-link" style="display: flex; align-items: center; justify-content: center;">
                <el-avatar :size="35" style="margin-right: 10px;" :src="user.userHeaderImg" shape="circle">未</el-avatar>
                <template v-if="user.userPhone !== null && user.userPhone !== undefined && user.userPhone !== ''">
                    <span>{{ user.userNickName }}</span>
                </template>
                <template v-else>
                    <span>未登录 ！</span>
                </template>
                <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="login">
                        <el-icon>
                            <InfoFilled />
                        </el-icon>前往登录
                    </el-dropdown-item>
                    <el-dropdown-item @click="logout">
                        <el-icon>
                            <SwitchButton />
                        </el-icon>退出系统
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </header>
</template>

<script>
export default {
    name: "HeaderPage",
    computed: {
        user: {
            get() {
                return this.$store.state.user;
            }
        },
    },
    methods: {
        navigateTo(routeName) {             
            this.$router.push({ name: routeName });     
            this.$store.commit('NavigationModule', routeName);      
            this.$store.commit("defaultEditTbale");                 
        },
        login() {
            //跳转到登录路由
            this.$router.push({ path: "/login" });
        },
        logout() {
            //清理数据
            this.$store.commit('resetState');
            //跳转到登录路由
            this.$router.push({ path: "/login" });
        },

    },
};
</script>

<style scoped>
.centerTitleMenu {
    display: flex;
    flex: 1;
    justify-content: center;
}

.centerTitleMenu ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.centerTitleMenu li {
    position: relative;
    list-style: none;
    padding: 10px;
    margin-right: 50px;
    transition: all 0.2s linear;
    color: #000;
    font-size: 16px;
    cursor: pointer;
}

.centerTitleMenu li::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    /* 初始位置在中间的左边 */
    width: 0;
    /* 初始宽度为0 */
    height: 3px;
    border-bottom: 3px solid #3B3B3B;
    transition: left 0.3s linear, width 0.3s linear;
}

.centerTitleMenu li:hover::before {
    left: 25%;
    /* hover时保持在中间的左边 */
    width: 50%;
    /* hover时宽度变为50% */
    border-bottom-color: #409EFF;
}


.centerTitleMenu li:active {
    background: #2F4F4F;
    color: #fff;
}

.centerTitleMenu li.selected {
    color: blue; /* 选中时字体变为蓝色 */
}
:deep(.el-tooltip__trigger:focus-visible) {
    outline: unset;
}

header {
    background-color: #ecf0f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>