import JSEncrypt from 'jsencrypt';

const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCxZMGZY3AVSgOYN+pSAcGyekCa
  SncE3WvIRCZyheAtlm/QM6oKhkqAmInW4vRavPOMZZLieacqqYCMoelhGc+3BRuB
  QKob5IAiRy/EamKIx9TCKwKIibKawul4p+AerQ9UNCGHI2nWXCATO6OYE+kyQwjG
  b9WSo59zM9yyhRfvwQIDAQAB`;

export default {
    /* JSEncrypt加密 */
    rsaPublicData(data) {
        var jsencrypt = new JSEncrypt();
        jsencrypt.setPublicKey(publicKey);
        // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
        var result = jsencrypt.encrypt(data);
        return result;
    },
    /* 加密 */
    encrypt(data) {
        const PUBLIC_KEY = publicKey;
        var encryptor = new JSEncrypt(); // 修改这里，使用 JSEncrypt 而不是 Encrypt
        encryptor.setPublicKey(PUBLIC_KEY);
        // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
        const result = encryptor.encryptLong(data);
        return result;
    }
};