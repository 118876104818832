import { handleRequest, instance,ElLoading, ElMessage } from './commonutil';
// 获取证书订单集
const getCertOrders = async (search_name, pager) => {
    const params = {
        domain: search_name,
        pageIndex: pager.index - 1,
        pageSize: pager.pageSize
    };
    const queryString = new URLSearchParams(params).toString();
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.get(`/api/Cert/GetPageCertOrders?${queryString}`);
    return await handleRequest(httpRequest, '证书订单加载中...');
};
// 创建证书订单
const postCreateCertOrder = async (inputdomain, selectdoamin) => {

    const domainArray = inputdomain
        .split("\n")
        .map((domain) => domain.trim())
        .filter((domain) => domain);
    const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}$/;
    const wildcardPattern = /^\*\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/;
    let isValid = false;
    let message = "";
    if (selectdoamin === "signledomain") {
        isValid =
            domainArray.length === 1 && domainArray.every((domain) => domainPattern.test(domain));
        message = "单域名证书：域名数量只能为1，且必须为有效域名";
    } else if (selectdoamin === "gernaldoamin") {
        isValid =
            domainArray.length === 1 && domainArray.every((domain) => wildcardPattern.test(domain));
        message =
            "泛域名证书：域名数量只能为1，且必须是有效的泛域名（如：*.lingyanspace.com）";
    } else {
        isValid =
            domainArray.length >= 2 && domainArray.every((domain) => domainPattern.test(domain) || wildcardPattern.test(domain));
        message =
            "多域名证书：域名数量不得少于2个，且每个都必须为有效的普通域名或泛域名";
    }
    if (!isValid) {
        ElMessage.warning(message);
        return;
    }
    const url =
        selectdoamin === "signledomain" || selectdoamin === "gernaldoamin" ? `/api/Cert/CreateCertOrder?domain=${encodeURIComponent(domainArray[0])}` : "/api/Cert/ManyCreateCertOrder";
    const data =
        selectdoamin === "signledomain" || selectdoamin === "gernaldoamin" ? null : domainArray;
    // 传递一个返回 Promise 的函数给 handleRequest
    const httpRequest = () => instance.post(url, data);
    return await handleRequest(httpRequest, '创建证书订单中...');
};
// 验证证书订单TXT记录
const putCheckCertOrderRecord = async (selectdoamin, certOrderId) => {
    let httpRequest;
    if (selectdoamin === "signledomain" || selectdoamin === "gernaldoamin") {
        httpRequest = () => instance.put(`/api/Cert/ChallengeCertOrderDns?certOrderId=${encodeURIComponent(certOrderId)}`);
    } else {
        httpRequest = () => instance.put(`/api/Cert/ManyChallengeCertOrderDns?certOrderId=${encodeURIComponent(certOrderId)}`);
    }
    return await handleRequest(httpRequest, '验证DNS的TXT解析记录中...');
};
//下载文件
const getDownloadFile = async (url, loadingText = "下载文件中...") => {
    const loadingInstance = ElLoading.service({
        lock: true,
        text: loadingText,
        background: "rgba(0, 0, 0, 0.7)",
    });
    try {
        let response = await instance.get(url, { responseType: "blob" });
        const { data, headers } = response;
        const fileName = url.split("/").pop();
        const blob = new Blob([data], { type: headers["content-type"] });
        const link = document.createElement("a");
        const downloadUrl = window.URL.createObjectURL(blob);
        link.href = downloadUrl;
        link.style.display = "none";
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        ElMessage.error(error.message);
    } finally {
        loadingInstance.close();
    }
};
//获取质检成功的证书
const getCertOrderSuccess = async (certOrderId) => {
    let httpRequest = () => instance.get(`/api/Cert/GetCertOrder?certOrderId=${encodeURIComponent(certOrderId)}`);
    return await handleRequest(httpRequest, '完成当前证书订单中...');
};
//删除证书订单
const delCertOrder = async (certOrderId) => {
    let httpRequest = () => instance.delete(`/api/Cert/DeleteCertOrder?certOrderId=${certOrderId}`);
    return await handleRequest(httpRequest, '删除证书订单中...');
};
//查询证书订单记录分页
const getCertOrderRecordPages = async (certOrderId, pager) => {
    let httpRequest = () => instance.get(`/api/Cert/GetCertOrderRecords?certOrderId=${certOrderId}&pageIndex=${pager.index - 1}&pageSize=${pager.pageSize}`);
    return await handleRequest(httpRequest, '查询证书订单签发记录中...');
};
//手动更新证书
const manualPutCertOrder = async (certOrderId) => {
    let httpRequest = () => instance.put(`/api/Cert/ManualUpdateCertOrderRecord?certOrderId=${certOrderId}`);
    return await handleRequest(httpRequest, '手动更新证书签发中...');
};
//获取监控记录
const geMonitorOrders = async (search_name, pager) => {
    let httpRequest = () => instance.get(`/api/Cert/GetCertMonitors?domain=${search_name}&pageIndex=${pager.index - 1}&pageSize=${pager.pageSize}`);
    return await handleRequest(httpRequest, '加载证书监控...');
};
//创建证书监控
const postCreateMonitorOrder = async (fromMonitoOrder) => {
    let httpRequest;
    if (fromMonitoOrder.monitorType === '证书监控') {
        httpRequest = () => instance.post(`/api/Cert/CreateCertMonitorFromCertOrder?certOrderId=${fromMonitoOrder.certOrderId}`);
    }
    else {
        httpRequest = () => instance.post(`/api/Cert/CreateCertMonitorFromDomain?domain=${fromMonitoOrder.domain}&ip=${fromMonitoOrder.ip}&port=${fromMonitoOrder.port}`);
    }
    return await handleRequest(httpRequest, '创建监控订单中...');
};
//删除监控订单
const deleteMonitorOrder = async (monitorOrderId) => {
    let httpRequest = () => instance.delete(`/api/Cert/DeleteMonitorOrder?monitorOrderId=${monitorOrderId}`);
    return await handleRequest(httpRequest, '删除监控订单中...');
};
//查询监控订单记录中
const getmonitorOrderRecordPages = async (monitorOrderId, pager) => {
    let httpRequest = () => instance.get(`/api/Cert/GetCertMonitorRecords?certMonitorId=${monitorOrderId}&pageIndex=${pager.index - 1}&pageSize=${pager.pageSize}`);
    return await handleRequest(httpRequest, '查询监控订单记录中...');
};
export default {
    getCertOrders,
    postCreateCertOrder,
    putCheckCertOrderRecord,
    getDownloadFile,
    getCertOrderSuccess,
    delCertOrder,
    getCertOrderRecordPages,
    manualPutCertOrder,
    geMonitorOrders,
    postCreateMonitorOrder,
    deleteMonitorOrder,
    getmonitorOrderRecordPages
};
