<template>
  <div class="container">
    <el-container>
      <el-header>
        <HeaderPage />
      </el-header>
      <el-container>
        <router-view>
        </router-view>
      </el-container>
      <el-footer>
        <FooterPage />
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import HeaderPage from "./Container/Header.vue";
import FooterPage from "./Container/Footer.vue";
export default {
  name: 'IndexPage',
  components: { HeaderPage, FooterPage },
  props: {
    msg: String
  }
}
</script>
<style scoped>
body>.el-container {
  margin-bottom: 0px;
}

.container {
  width: 100%;
  background-color: #ecf0f3;
}

.container>.el-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
}

.el-header {
  background-color: #ecf0f3;
  color: #333;
  text-align: center;
  justify-content: center;
  height: 45px;
  padding: 0;
}

.el-footer {
  color: #333;
  text-align: center;
  height: 30px;
  line-height: 30px;
  justify-content: center;
}
</style>
