<!-- sign_up -->
<template>
    <div class="container a-container" id="a-container">
        <el-form ref="registerRulesRef" :model="registerFrom" :rules="registerRules" class="form" id="a-form">
            <h2 class="form_title title">注册账户</h2>
            <span class="form__span">注册需要使用电话验证码</span>
            <el-form-item prop="userNickName">
                <el-input v-model="registerFrom.userNickName" style="width: 350px;" class="form__input" link
                    placeholder="昵称" clearable />
            </el-form-item>
            <el-form-item prop="userPhone">
                <el-input v-model="registerFrom.userPhone" style="width: 350px;" class="form__input" link
                    placeholder="电话" clearable />
            </el-form-item>
            <el-form-item prop="userPassword">
                <el-input v-model="registerFrom.userPassword" style="width: 350px;" class="form__input" type="password"
                    placeholder="第一次输入密码" clearable show-password />
            </el-form-item>
            <el-form-item prop="checkPassword">
                <el-input v-model="registerFrom.checkPassword" style="width: 350px;" class="form__input" type="password"
                    placeholder="第二次输入密码" clearable show-password />
            </el-form-item>
            <el-form-item prop="validateCode">
                <el-input v-model="registerFrom.validateCode" style="width: 350px;" class="form__input" link
                    placeholder="验证码" clearable></el-input>
            </el-form-item>
            <el-button type="success" link @click="sendValidateCode" :loading="isCounting" style="margin-left: 280px;">
                <template #loading>
                    <div class="custom-loading">
                        （{{ sendCodeTimeOut }}）s后过期
                    </div>
                </template>
                <span v-if="isCounting"></span>
                <span v-else>发送验证码</span>
            </el-button>
            <button class="form__button button submit" @click.prevent="signup(registerRulesRef)">注册</button>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'signupPage',
    data() {
        return {
            isCounting: false,
            sendCodeTimeOut: 180,
            isOpen: true,
            registerFrom: {
                userNickName: '',
                userPhone: '',
                userPassword: '',
                checkPassword: '',
                validateCode: ''
            },
            registerRules: {
                userNickName: [
                    { required: true, message: '请输入昵称', trigger: 'blur' },
                    { min: 2, message: '用户名必须大于2个字符', trigger: 'blur' }
                ],
                userPhone: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                    { min: 10, message: '电话长度必须大于10', trigger: 'blur' }
                ],
                userPassword: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 3, message: '密码必须大于3个字符', trigger: 'blur' },
                    { validator: this.validatePass, trigger: 'blur' }
                ],
                checkPassword: [
                    { required: true, message: '请再次输入密码', trigger: 'blur' },
                    { min: 3, message: '密码必须大于3个字符', trigger: 'blur' },
                    { validator: this.validatePass2, trigger: 'blur' }
                ],
                validateCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 5, message: '验证码必须大于5个字符', trigger: 'blur' }
                ]
            }

        };
    },
    methods: {
        validatePass(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.registerFrom.checkPassword !== '') {
                    this.$nextTick(() => {
                        if (this.registerRulesRef) {
                            this.registerRulesRef.validateField('checkPassword');
                        }
                    });
                }
                callback();
            }
        },
        validatePass2(rule, value, callback) {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.registerFrom.userPassword) {
                callback(new Error("两次密码不一致!"));
            } else {
                callback();
            }
        },
        async signup() {
            this.$refs.registerRulesRef.validate(async (valid) => {
                if (valid) {
                    var data = await this.$userutil.signup(this.registerFrom);
                    if (data !== undefined) {
                        //存储token到本地
                        this.$store.commit("SET_TOKEN", data);
                        //跳转到主页                       
                        this.$router.replace('/Index');
                        this.$store.commit("defaultEditTbale",);
                    }
                } else {
                    this.$message.error('验证不通过');
                }
            });
        },
        async sendValidateCode() {
            if (this.isCounting) return;
            if (this.registerFrom.userPhone) {
                var data = await this.$userutil.sendValidateCode(this.registerFrom.userPhone);
                if (data !== undefined) {
                    // 启动倒计时
                    this.isCounting = true;
                    let countdown = this.sendCodeTimeOut;
                    const interval = setInterval(() => {
                        this.sendCodeTimeOut = countdown--;
                        if (countdown <= 0) {
                            clearInterval(interval);
                            this.sendCodeTimeOut = 180;
                            this.isCounting = false;
                        }
                    }, 1000);
                    this.$message.success('验证码已发送，请注意查收');
                }
            } else {
                this.$message.error('请输入手机号码');
            }
        }
    },
    created() {
        this.$emitter.on('change', (newIsOpen) => {
            this.isOpen = newIsOpen;
        });
    },
    mounted() {
        this.$watch('isOpen', () => {
            const aContainer = document.querySelector('#a-container');
            aContainer.classList.toggle('is-txl');
        });
    }
};   
</script>
<style scoped>
@import './login.css';
</style>