<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'App',
  methods: {
    handleBeforeUnload() {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state));
    }
  },
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      // 存储状态
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
    }
  },
  beforeUnmount() {
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    this.handleBeforeUnload();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  mounted() {
    // 添加 beforeunload 事件监听器
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }
}
</script>

<style>
</style>
